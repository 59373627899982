import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Home";
import DecisionHome from "./components/DecisionHome";
import DailyEfficency from "./components/DailyEfficency";
import LineModuleEfficency from "./components/LineModuleEfficency";

function App() {
  return (
    // <Home/>
    // <DecisionHome/>
    <div>
      <Router>
        {/* <Route path="/" element={<DecisionHome />} />
        <Route path="/home" element={<Home />} /> */}

        <Switch>
          <Route exact path="/">
            <DecisionHome />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/dailyEfficency">
            <DailyEfficency />
          </Route>
          <Route path="/lineModuleEfficency">
            <LineModuleEfficency />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
