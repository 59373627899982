import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import axios from "axios";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DescriptionIcon from "@material-ui/icons/Description";
import CircularProgress from "@material-ui/core/CircularProgress";


import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10vh 10vw",
    height: "80vh",
    width: "80vw",
    borderRadius: "5vh",
  },
  content: {
    height: "80vh",
    width: "80vw",
    borderRadius: "5vh",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  input: {
    display: "none",
  },
  iconButton: {
    background: "#ccc",
    margin: "2rem",
  },
  fileName: {
    color: "#555",
    margin: "1rem",
  },
}));

function GenericModal(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <Modal
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
      className={classes.root}
    >
      <div className={classes.content}>
        <h1>Generic Page!!!, anything can be added</h1>
      </div>
    </Modal>
  );
}

export default GenericModal;
