import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Button, Grid, Fab, Modal } from "@material-ui/core";

import {
  // FcAndroidOs,
  FcStatistics,
  FcWorkflow,
  FcBinoculars,
} from "react-icons/fc";

import SettingsIcon from "@material-ui/icons/Settings";

import { makeStyles } from "@material-ui/core";
import ClientConfigurations from "./ClientConfigurations";
import Layout from "./common/Layout";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5vh 5vw",
    minHeight: "100vh",
    maxHeight: "100vh",
    background: "linear-gradient(to right top, #2F464D, #6c8f99)",
  },
  mainContainer: {
    padding: "5vh 5vw",
    [theme.breakpoints.down("md")]: {
      padding: "5vh 0vw",
    },
    minHeight: "80vh",
    width: "90vw",
    borderRadius: "10px",
    background:
      "linear-gradient(to left bottom, rgba(255,255,255,0.2), rgba(255,255,255,0.1))",
  },
  footerContainer: {
    minHeight: "10vh",
    width: "90vw",
    display: "flex",
    alignContent: "center",
  },
  settingsButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    width: "3em",
    height: "3em",
    background: "rgba(255,255,255,0.5)",
  },
  title: {
    color: "rgba(255,255,255,0.8)",
    fontWeight: 900,
    padding: "2% 0.5%",
    textAlign: "center",
    fontSize: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  subtitle: {
    color: "rgba(255,255,255,0.8)",
    fontWeight: 400,
    textAlign: "center",
    fontSize: "1.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  buttonsSelectInfo: {
    color: "rgba(255,255,255,0.7)",
    fontWeight: 600,
    textAlign: "center",
    fontSize: "1.2rem",
    marginTop: "10vh",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
  },

  footer: {
    color: "rgb(155,155,155)",
    fontWeight: 600,
    textAlign: "center",
    fontSize: "1rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
  },
  buttonsContainer: {
    margin: "2rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    textAlign: "center",
  },
  button: {
    backgroundColor: "#E7C980",
    borderRadius: "7px",
    height: "4rem",
    width: "100%",
    color: "rgba(50,50,50,1)",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem",
    },
    "&:hover": {
      backgroundColor: "#d5A553",
      background:
        "linear-gradient(to right bottom, rgba(56,55,95,0.5), rgba(255,255,255,0.3))",
    },
  },
}));

function DecisionHome() {
  const classes = useStyles();
  const history = useHistory();

  const [openConf, setOpenConf] = useState(false);

  const [userIsLogged, setuserIsLogged] = useState(false);

  const closeConf = () => {
    setOpenConf(false);
  };

  useEffect(() => {
    if (localStorage.getItem("client") && localStorage.getItem("empNum")) {
      setuserIsLogged(true);
    } else {
      localStorage.setItem("client", "demo");
      localStorage.setItem("empNum", "1");
      setuserIsLogged(true);
    }
  }, []);

  return (
    <Layout>
      <div className={classes.mainContainer}>
        <Fab
          className={classes.settingsButton}
          onClick={() => {
            setOpenConf(true);
          }}
        >
          <SettingsIcon className={classes.icon} />
        </Fab>
        <Typography className={classes.title}>
          {" "}
          Welcome to International Systems, Inc .
        </Typography>
        <Typography className={classes.subtitle}>
          The most reliable system for the industry
        </Typography>
        <Typography className={classes.buttonsSelectInfo}>
          {" "}
          Select the option you want to use{" "}
        </Typography>
        {userIsLogged ? (
          <div className={classes.buttonsContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Button
                  className={classes.button}
                  onClick={() => {
                    if (
                      localStorage.getItem("client") !== "" &&
                      localStorage.getItem("client")
                    ) {
                      history.push("/home");
                    } else {
                      alert("Select your client information in settings");
                    }
                  }}
                  startIcon={<FcStatistics />}
                >
                  ELECTRONIC SCOREBOARD INTSYS - HOURLY
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  className={classes.button}
                  onClick={() => {
                    if (
                      localStorage.getItem("client") !== "" &&
                      localStorage.getItem("client")
                    ) {
                      history.push("/dailyEfficency");
                    } else {
                      alert("Select your client information in settings");
                    }
                  }}
                  startIcon={<FcWorkflow />}
                >
                  Daily efficiency
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  className={classes.button}
                  onClick={() => {
                    if (
                      localStorage.getItem("client") !== "" &&
                      localStorage.getItem("client")
                    ) {
                      history.push("/lineModuleEfficency");
                    } else {
                      alert("Select your client information in settings");
                    }
                  }}
                  startIcon={<FcBinoculars />}
                >
                  Line -module efficiency
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          <ClientConfigurations setuserIsLogged={setuserIsLogged} />
        )}
      </div>
      <Grid container className={classes.footerContainer}>
        <Grid item xs={12} md={6} className={classes.footer}>
          <a
            className={classes.footer}
            href={`mailto:info@isiww.biz`}
            target="/blank"
          >
            {" "}
            info@isiww.biz
          </a>
        </Grid>
        <Grid item xs={12} md={6} className={classes.footer}>
          USA 1-818-434-3645
        </Grid>
      </Grid>
      <Modal open={openConf}>
        <div className={classes.modal}>
          <ClientConfigurations closeConf={closeConf} />
        </div>
      </Modal>
    </Layout>
  );
}

export default DecisionHome;
