import React, { useEffect, useState } from "react";
import { Fab, makeStyles, MenuItem, TextField } from "@material-ui/core";
import {
  Grid,
  Typography,
  Button,
  Select,
  InputLabel,
} from "@material-ui/core";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

import { getDateInFormat } from "../Utils";
import axios from "axios";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2.5vh 2.5vw ",
    height: "100%",
    width: "100%",
    background: "linear-gradient(to right top, #B0B2AA, #C0B5B4)",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "center",
    justifyContent: "space-between",
  },
  clientContainer: {
    width: "100%",
    height: "15%",
    backgroundColor: "rgba(154, 142, 149,0.5)",
    padding: "2vh 2vw",
    border: "solid",
    borderWidth: "1px",
    borderRadius: "10px",
    borderColor: "rgba(255,255,255,0.1)",
    display: "flex",
    justifyContent: "space-evenly",
  },
  clientOptions: {
    width: "100%",
    display: "flex",
  },
  clientTitle: {
    fontSize: "2rem",
    color: "#303050",
    fontWeight: 900,
  },
  clientText: {
    width: "50%",
    paddingBottom: "2vh",
  },
  searchIcon: {
    fontSize: "3rem",
    color: "#2F464D",
  },
  linesRoot: {
    padding: "2vh 2vw",
    border: "solid",
    borderColor: "rgba(255,255,255,0.1)",
    backgroundColor: "rgba(154, 142, 149,0.5)",
    borderWidth: "1px",
    borderRadius: "10px",
    width: "100%",
    height: "80%",
  },
  linesTitle: {
    fontSize: "2rem",
    paddingBottom: "1vh",
  },
  linesContainer: {
    display: "flex",
    marginTop: "2%",
    height: "90%",
  },
  linesOptionsContainer: {
    width: "50%",
    padding: "2vh 2vw",
    display: "flex",
    flexDirection: "columns",
    justifyContent: "space-between",
  },
  lineOption: {
    border: "solid",
    borderColor: "transparent",
    borderBlockWidth: "0.5px",
  },
  available: {
    backgroundColor: "#C5A553",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    "&:hover": {
      // backgroundColor: "linear-gradient(to right top, #71bc9e, #6c8f19)",
      backgroundColor: "#C5A553",
    },
  },
  disavailable: {
    backgroundColor: "#B0B2AA",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: "#B0B2AA",
    },
  },
  buttonText: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  linesOptionsPicker: {
    padding: "2vh 2vw",
    borderRadius: "10px",
    width: "50%",
    height: "100%",
    display: "inline-block",
    backgroundColor: "#E7C980",
  },
  select: {
    backgroundColor: "#E7C980",
  },
}));

function Configurations(props) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [dataInConfig, setDataInConfig] = useState([]);
  const [dataFromLocalStorage, setDataFromLocalStorage] = useState([]);
  const [selections, setSelections] = useState([]);
  const [showSelect, setShowSelect] = useState(false);
  const [optSelected, setOptSelected] = useState(0);
  const [reportType, setReportType] = useState("Normal");
  const [date, setDate] = useState( getDateInFormat(new Date())
  );

  const setSelectToOption = (optionSelected) => {
    let toPush = {};
    const foundInData = data.filter((el) => {
      return el.numdept.toString() === optionSelected.toString();
    });
    if (foundInData.length > 0) {
      let auxNewData = dataInConfig;
      for (var i = 0; i < auxNewData.length; i++) {
        if (auxNewData[i].numdept.toString() === optionSelected.toString()) {
          auxNewData.splice(i, 1);
        }
        toPush = {
          numdept: foundInData[0].numdept.toString(),
          supervisor: foundInData[0].supervisor,
        };
      }
      setDataInConfig(auxNewData);
      let auxSelections = selections;
      switch (optSelected) {
        case 1:
          setShowSelect(false);
          setOptSelected(0);
          auxSelections[0] = toPush;
          setSelections(auxSelections);
          break;
        case 2:
          setShowSelect(false);
          setOptSelected(0);
          auxSelections[1] = toPush;
          setSelections(auxSelections);
          break;
        case 3:
          setShowSelect(false);
          setOptSelected(0);
          auxSelections[2] = toPush;
          setSelections(auxSelections);
          break;
        case 4:
          setShowSelect(false);
          setOptSelected(0);
          auxSelections[3] = toPush;
          setSelections(auxSelections);
          break;
        default:
          break;
      }
    }
  };

  console.log(getDateInFormat(new Date()));

  useEffect(() => {
    setSelections(dataFromLocalStorage);
    let auxD = data.slice();
    auxD = auxD.filter((d) => {
      const found = dataFromLocalStorage.find(
        (ds) => ds.numdept.toString() === d.numdept.toString()
      );
      if (found) return false;
      return true;
    });
    setDataInConfig(auxD);
  }, [data, dataFromLocalStorage]);

  useEffect(() => {
    const dataFromLocalSt = [
      {
        numdept: localStorage.getItem("numdept1")
          ? localStorage.getItem("numdept1")
          : "0",
        supervisor: localStorage.getItem("supervisor1")
          ? localStorage.getItem("supervisor1")
          : "N/A",
      },
      {
        numdept: localStorage.getItem("numdept2")
          ? localStorage.getItem("numdept2")
          : "0",
        supervisor: localStorage.getItem("supervisor2")
          ? localStorage.getItem("supervisor2")
          : "N/A",
      },
      {
        numdept: localStorage.getItem("numdept3")
          ? localStorage.getItem("numdept3")
          : "0",
        supervisor: localStorage.getItem("supervisor3")
          ? localStorage.getItem("supervisor3")
          : "N/A",
      },
      {
        numdept: localStorage.getItem("numdept4")
          ? localStorage.getItem("numdept4")
          : "0",
        supervisor: localStorage.getItem("supervisor4")
          ? localStorage.getItem("supervisor4")
          : "N/A",
      },
    ];
    const clientFromLS = localStorage.getItem("client");
    setDataFromLocalStorage(dataFromLocalSt);
    setSelections(dataFromLocalSt);

    const reportTypeFromLS = localStorage.getItem("reportType");
    setReportType(reportTypeFromLS ? reportTypeFromLS : "Normal");

    axios
      .get(`${serverUrl}/api/tickets/departments/${clientFromLS}`)
      .then((deptData) => {
        setData(deptData.data);
      })
      .catch((err) => {
        alert("Go back to home and select your correct client");
        setData([]);
        console.log(err);
      });
  }, []);

  const closeModal = () => {
    props.closeConf();
  };

  const handleChangeReportType = (event) => {
    setReportType(event.target.value);
  };

  const save = () => {
    localStorage.setItem("reportType", reportType);
    props.setStrDate(date);
    localStorage.setItem("date", date);
    if (selections[0]?.numdept) {
      localStorage.setItem("numdept1", selections[0].numdept);
    } else {
      localStorage.setItem("numdept1", 0);
    }
    if (selections[1]?.numdept) {
      localStorage.setItem("numdept2", selections[1].numdept);
    } else {
      localStorage.setItem("numdept2", 0);
    }
    if (selections[2]?.numdept) {
      localStorage.setItem("numdept3", selections[2].numdept);
    } else {
      localStorage.setItem("numdept3", 0);
    }
    if (selections[3]?.numdept) {
      localStorage.setItem("numdept4", selections[3].numdept);
    } else {
      localStorage.setItem("numdept4", 0);
    }

    if (selections[0]?.supervisor) {
      localStorage.setItem("supervisor1", selections[0].supervisor);
    } else {
      localStorage.setItem("supervisor1", "N/A");
    }
    if (selections[1]?.supervisor) {
      localStorage.setItem("supervisor2", selections[1].supervisor);
    } else {
      localStorage.setItem("supervisor2", "N/A");
    }
    if (selections[2]?.supervisor) {
      localStorage.setItem("supervisor3", selections[2].supervisor);
    } else {
      localStorage.setItem("supervisor3", "N/A");
    }
    if (selections[3]?.supervisor) {
      localStorage.setItem("supervisor4", selections[3].supervisor);
    } else {
      localStorage.setItem("supervisor4", "N/A");
    }
    props.closeConf();
  };

  return (
    <div className={classes.root}>
      <Fab
        onClick={() => {
          closeModal();
        }}
      >
        <CloseIcon />
      </Fab>
      <Fab
        onClick={() => {
          save();
        }}
      >
        <SaveIcon />
      </Fab>
      <div className={classes.clientContainer}>
        <div className={classes.clientOptions}>
          <InputLabel id="report-type-label">Report Type</InputLabel>
          <Select
            labelId="report-type-label"
            id="report-type"
            value={reportType}
            onChange={handleChangeReportType}
          >
            <MenuItem value={"Normal"}>Normal</MenuItem>
            <MenuItem value={"Module"}>Module</MenuItem>
          </Select>
        </div>
        <div className={classes.clientOptions}>
          <InputLabel id="date-label">Select Date </InputLabel>
          <TextField
            value={date}
            type="date"
            InputProps={{
              inputProps: { max: getDateInFormat(new Date()) },
            }}
            onChange={(e) => {
              setDate(e.target.value);
            }}
          />
        </div>
      </div>

      <div className={classes.linesRoot}>
        <div>
          <Typography className={classes.clientTitle}>Lines: </Typography>
        </div>
        <div className={classes.linesContainer}>
          <Grid container className={classes.linesOptionsContainer}>
            {[0, 1, 2, 3].map((index) => (
              <Grid item xs={6} key={index} className={classes.lineOption}>
                <Button
                  className={
                    selections[index]?.numdept > 0
                      ? classes.available
                      : classes.disavailable
                  }
                  onClick={() => {
                    if (parseInt(selections[index]?.numdept) > 0) {
                      setShowSelect(false);
                      const auxToPush = data?.slice().find((el) => {
                        return (
                          el.numdept.toString() ===
                          selections[index].numdept.toString()
                        );
                      });

                      let auxNewDat = dataInConfig;
                      if (auxToPush) auxNewDat.push(auxToPush);
                      setDataInConfig(auxNewDat);
                      let auxSelections = selections.slice();
                      auxSelections.splice(index, 1);
                      setSelections(auxSelections);
                    } else {
                      setShowSelect(true);
                      setOptSelected(index + 1);
                    }
                  }}
                >
                  <p className={classes.buttonText}>
                    Module-Line:
                    {selections[index]?.numdept
                      ? selections[index].numdept
                      : ""}
                    <br />
                    <span>
                      {" "}
                      <b>
                        supervisor:{" "}
                        {selections[index]?.supervisor
                          ? selections[index].supervisor
                          : "N/A"}{" "}
                      </b>
                    </span>
                  </p>
                </Button>
              </Grid>
            ))}
          </Grid>
          {showSelect ? (
            <div className={classes.linesOptionsPicker}>
              <List>
                {dataInConfig.length > 0 &&
                  dataInConfig?.map((el) => (
                    <ListItem
                      button
                      key={el.numdept}
                      onClick={() => setSelectToOption(el.numdept)}
                    >
                      <ListItemText
                        primary={`${el.numdept} - ${el.supervisor}`}
                      />
                    </ListItem>
                  ))}
              </List>
              {/* <Select
                className={classes.select}
                fullWidth
                displayEmpty
                value={0}
                variant="outlined"
                onChange={(e) => {
                  setSelectToOption(e.target.value);
                }}
              >
                <MenuItem key={0} value={0} className={classes.select}>
                  N/A
                </MenuItem>
                {dataInConfig.map((el) => {
                  return (
                    <MenuItem
                      key={el.numdept}
                      value={el.numdept}
                      className={classes.select}
                    >
                      {`${el.numdept} - ${el.supervisor}`}
                    </MenuItem>
                  );
                })}
              </Select> */}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Configurations;
