import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import axios from "axios";

import {
  Button,
  CircularProgress,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  easing,
  makeStyles,
} from "@material-ui/core";

import { ArrowBackOutlined } from "@material-ui/icons";

import Layout from "./common/Layout";
import { getDateInFormat } from "../Utils";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const useStyles = makeStyles((theme) => ({
  loading: {
    position: "absolute",
    left: "25%",
    top: "47%",
    width: "50%",
    height: "5%",
    background:
      "linear-gradient(to left bottom, rgba(255,255,255,0.9), rgba(255,255,255,0.4))",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  progress: {
    fontSize: "2rem",
    fontWeight: 900,
    color: "rgb(200,120,120)",
  },
  mainContainer: {
    minHeight: "90vh",
    width: "90vw",
    borderRadius: "10px",
    background:
      "linear-gradient(to left bottom, rgba(255,255,255,0.2), rgba(255,255,255,0.1))",
  },
  backButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    width: "3em",
    height: "3em",
    background: "rgba(255,255,255,0.5)",
  },
  clientTitle: {
    fontSize: "2rem",
    color: "#303050",
    fontWeight: 900,
    textAlign: "center",
  },
  clientOptions: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  dataContainer: {
    margin: "2rem 0",
    width: "100%",
    // display: "flex",
  },
  dataTitles: {
    fontWeight: 700,
    overflow: "hidden",
    textAlign: "center",
    padding: "0.5vh 0",
  },
  dataContentOdd: {
    padding: "0.5vh 0",
    overflow: "hidden",
    textAlign: "center",
    backgroundColor: "rgba(255,255,255,0.3)",
  },
  dataContentEven: {
    padding: "0.5vh 0",
    overflow: "hidden",
    textAlign: "center",
    backgroundColor: "rgba(255,255,255,0.5)",
  },
}));

const messageDict = {
  "You Need Help": "HELP",
  "Your work is normal": "OK",
  "You are doing really good": "GOOD",
};

function DailyEfficency() {
  const classes = useStyles();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(getDateInFormat(new Date()));
  const [dataFromReport, setdataFromReport] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [departmentsAvailable, setdepartmentsAvailable] = useState(["All"]);
  const [selectedDept, setSelectedDept] = useState("All");

  useEffect(() => {
    getReportDataByDate();
  }, []);

  const getReportDataByDate = (newDate) => {
    setIsLoading(true);
    setfilteredData([]);
    axios
      .post(`${serverUrl}/api/tickets/efficiency-summary`, {
        date: newDate ?? date,
        client: localStorage.getItem("client"),
      })
      .then((data) => {
        setdataFromReport(data.data);
      })
      .catch((err) => {
        console.log(err);
        setdataFromReport([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (dataFromReport) {
      filterDepartments(selectedDept);
      let auxDepts = ["All"];
      dataFromReport?.forEach((d) => {
        if (!auxDepts.includes(d.empDept)) {
          auxDepts.push(d.empDept);
        }
      });
      setdepartmentsAvailable([...new Set(auxDepts)]);
    }
  }, [dataFromReport]);

  const filterDepartments = (dept) => {
    console.log(dataFromReport);
    if (dept.isEmpty) {
      setfilteredData([]);
    } else if (dept === "All") {
      setfilteredData(dataFromReport);
    } else {
      const filtered = dataFromReport.filter((element) => {
        return element.empDept === dept;
      });
      setfilteredData(filtered);
    }
  };

  const getReport = () => {
    let doc = new jsPDF();
    doc.setFontSize("20");
    doc.setTextColor("blue");
    doc.text("DAILY  EFFICENCY", 100, 20, "center");

    doc.setTextColor("black");
    doc.setFontSize("12");
    doc.text("Date: ", 20, 40);
    doc.text(date, 120, 40);

    const items = filteredData
      ?.sort((a, b) => a.empNum - b.empNum)
      .map((el) => {
        return [
          el.empNum,
          el.empDept,
          el.firstName + " " + el.lastName,
          el.avg.toFixed(2),
          Math.ceil(el.efficiency * 100),
          el.hourly.toFixed(2),
          el.earnings,
          el.tickets.toFixed(2),
          (el.tickets - el.hourly).toFixed(2),
          messageDict[el.message],
        ];
      });
    doc.autoTable({
      margin: { top: 60 },
      head: [
        [
          "Emp #",
          "Dpt",
          "Name",
          "Avrg",
          "Eff",
          "Earnings",
          "Worked Time",
          "Tickets",
          "Profit",
          "Message",
        ],
      ],
      body: items,
    });

    doc.save(`DailyEfficency-${date}.pdf`);
  };

  return (
    <Layout>
      <div className={classes.mainContainer}>
        <Typography className={classes.clientTitle}>Daily Reports</Typography>
        <div className={classes.clientOptions}>
          <div>
            <InputLabel id="date-label">Select Date </InputLabel>
            <TextField
              value={date}
              type="date"
              InputProps={{
                inputProps: { max: getDateInFormat(new Date()) },
              }}
              onChange={(e) => {
                setSelectedDept("All");
                setDate(e.target.value);
                getReportDataByDate(e.target.value);
              }}
            />
          </div>
          <FormControl>
            <InputLabel id="dept">Department</InputLabel>
            <Select
              value={selectedDept}
              onChange={(e) => {
                setSelectedDept(e.target.value);
                filterDepartments(e.target.value);
              }}
            >
              {departmentsAvailable?.map((d) => (
                <MenuItem key={d} value={d}>
                  {d}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            onClick={() => {
              getReport();
            }}
          >
            Download Report
          </Button>
        </div>
        <div className={classes.dataContainer}>
          <Grid container>
            <Grid item className={classes.dataTitles} xs={1}>
              Emp #
            </Grid>
            <Grid item className={classes.dataTitles} xs={1}>
              Dept
            </Grid>
            <Grid item className={classes.dataTitles} xs={2}>
              Name
            </Grid>
            <Grid item className={classes.dataTitles} xs={1}>
              Avg
            </Grid>
            <Grid item className={classes.dataTitles} xs={1}>
              Eff
            </Grid>
            <Grid item className={classes.dataTitles} xs={1}>
              Earnings
            </Grid>
            <Grid item className={classes.dataTitles} xs={1}>
              Worked Time
            </Grid>
            <Grid item className={classes.dataTitles} xs={1}>
              Tickets
            </Grid>
            <Grid item className={classes.dataTitles} xs={1}>
              Profit
            </Grid>
            <Grid item className={classes.dataTitles} xs={2}>
              Message
            </Grid>
            {filteredData
              ?.sort((a, b) => a.empNum - b.empNum)
              .map((row, index) => {
                return (
                  <React.Fragment key={index}>
                    <Grid
                      item
                      className={
                        index % 2 === 0
                          ? classes.dataContentEven
                          : classes.dataContentOdd
                      }
                      xs={1}
                    >
                      {row.empNum}
                    </Grid>
                    <Grid
                      item
                      className={
                        index % 2 === 0
                          ? classes.dataContentEven
                          : classes.dataContentOdd
                      }
                      xs={1}
                    >
                      {row.empDept}
                    </Grid>
                    <Grid
                      item
                      className={
                        index % 2 === 0
                          ? classes.dataContentEven
                          : classes.dataContentOdd
                      }
                      xs={2}
                    >
                      {`${row.firstName} ${row.lastName}`}
                    </Grid>
                    <Grid
                      item
                      className={
                        index % 2 === 0
                          ? classes.dataContentEven
                          : classes.dataContentOdd
                      }
                      xs={1}
                    >
                      {row.avg.toFixed(2)}
                    </Grid>
                    <Grid
                      item
                      className={
                        index % 2 === 0
                          ? classes.dataContentEven
                          : classes.dataContentOdd
                      }
                      xs={1}
                    >
                      {Math.ceil(row.efficiency * 100)}
                    </Grid>
                    <Grid
                      item
                      className={
                        index % 2 === 0
                          ? classes.dataContentEven
                          : classes.dataContentOdd
                      }
                      xs={1}
                    >
                      {row.hourly.toFixed(2)}
                    </Grid>
                    <Grid
                      item
                      className={
                        index % 2 === 0
                          ? classes.dataContentEven
                          : classes.dataContentOdd
                      }
                      xs={1}
                    >
                      {row.earnings}
                    </Grid>
                    <Grid
                      item
                      className={
                        index % 2 === 0
                          ? classes.dataContentEven
                          : classes.dataContentOdd
                      }
                      xs={1}
                    >
                      {row.tickets.toFixed(2)}
                    </Grid>
                    <Grid
                      item
                      className={
                        index % 2 === 0
                          ? classes.dataContentEven
                          : classes.dataContentOdd
                      }
                      xs={1}
                    >
                      {(row.tickets - row.hourly).toFixed(2)}
                    </Grid>
                    <Grid
                      item
                      className={
                        index % 2 === 0
                          ? classes.dataContentEven
                          : classes.dataContentOdd
                      }
                      xs={2}
                    >
                      {messageDict[row.message]}
                    </Grid>
                  </React.Fragment>
                );
              })}
          </Grid>
        </div>
        {isLoading ? (
          <div className={classes.loading}>
            <CircularProgress
              className={classes.progress}
              disableShrink={true}
            />
            <Typography>Loading....</Typography>
          </div>
        ) : null}
      </div>
      <Fab
        className={classes.backButton}
        onClick={() => {
          history.push("/");
        }}
      >
        <ArrowBackOutlined />
      </Fab>
    </Layout>
  );
}

export default DailyEfficency;
