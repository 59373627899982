import React, { useEffect, useState } from "react";
import { Fab, IconButton, makeStyles, MenuItem } from "@material-ui/core";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Select,
  InputLabel,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

import axios from "axios";
import { SearchSharp } from "@material-ui/icons";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2.5vh 2.5vw ",
    height: "100%",
    width: "100%",
    background: "linear-gradient(to right top, #B0B2AA, #C0B5B4)",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "center",
    justifyContent: "space-between",
  },
  clientContainer: {
    width: "100%",
    height: "15%",
    backgroundColor: "rgba(154, 142, 149,0.5)",
    padding: "2vh 2vw",
    border: "solid",
    borderWidth: "1px",
    borderRadius: "10px",
    borderColor: "rgba(255,255,255,0.1)",
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  clientOptions: {
    width: "100%",
    display: "flex",
  },
  clientTitle: {
    fontSize: "2rem",
    color: "#303050",
    fontWeight: 900,
  },
  clientText: {
    width: "50%",
    paddingBottom: "2vh",
  },
  searchIcon: {
    fontSize: "3rem",
    color: "#2F464D",
  },
  linesRoot: {
    padding: "2vh 2vw",
    border: "solid",
    borderColor: "rgba(255,255,255,0.1)",
    backgroundColor: "rgba(154, 142, 149,0.5)",
    borderWidth: "1px",
    borderRadius: "10px",
    width: "100%",
    height: "80%",
  },
  linesTitle: {
    fontSize: "2rem",
    paddingBottom: "1vh",
  },
  linesContainer: {
    display: "flex",
    marginTop: "2%",
    height: "90%",
  },
  linesOptionsContainer: {
    width: "50%",
    padding: "2vh 2vw",
    display: "flex",
    flexDirection: "columns",
    justifyContent: "space-between",
  },
  lineOption: {
    border: "solid",
    borderColor: "transparent",
    borderBlockWidth: "0.5px",
  },
  available: {
    backgroundColor: "#C5A553",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    "&:hover": {
      // backgroundColor: "linear-gradient(to right top, #71bc9e, #6c8f19)",
      backgroundColor: "#C5A553",
    },
  },
  disavailable: {
    backgroundColor: "#B0B2AA",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: "#B0B2AA",
    },
  },
  buttonText: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  linesOptionsPicker: {
    padding: "2vh 2vw",
    borderRadius: "10px",
    width: "50%",
    height: "100%",
    display: "inline-block",
    backgroundColor: "#E7C980",
  },
  select: {
    backgroundColor: "#E7C980",
  },
}));

function ClientConfigurations(props) {
  const classes = useStyles();
  const [client, setClient] = useState(localStorage.getItem("client") ?? "");
  const [empNum, setempNum] = useState(localStorage.getItem("empNum") ?? 0);

  const closeModal = () => {
    props.closeConf();
  };

  const save = () => {
    debugger;
    axios
      .post(`${serverUrl}/api/tickets/validateEmployee`, {
        empNum,
        client,
      })
      .then((data) => {
        debugger;
        localStorage.setItem("client", client);
        localStorage.setItem("empNum", empNum);
        if (props.closeConf) {
          props.closeConf();
        }
        if (props.setuserIsLogged) {
          props.setuserIsLogged(true);
        }
      })
      .catch((err) => {
        console.log(err.message);
        alert("User or client not found");
      });
  };

  return (
    <div className={classes.root}>
      {props.closeConf && (
        <Fab
          onClick={() => {
            closeModal();
          }}
        >
          <CloseIcon />
        </Fab>
      )}
      <Fab
        onClick={() => {
          save();
        }}
      >
        <SaveIcon />
      </Fab>
      <div className={classes.clientContainer}>
        <div className={classes.clientOptions}>
          <div>
            <Typography className={classes.clientTitle}>Client: </Typography>
          </div>
          <div>
            <TextField
              className={classes.clientText}
              value={client}
              variant="outlined"
              onChange={(e) => {
                setClient(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={classes.clientOptions}>
          <div>
            <Typography className={classes.clientTitle}>Employee Id</Typography>
          </div>
          <div>
            <TextField
              className={classes.clientText}
              type="number"
              value={empNum}
              variant="outlined"
              onChange={(e) => {
                setempNum(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientConfigurations;
