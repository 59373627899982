import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "0px",
    padding: "5vh 5vw",
    minHeight: "100vh",
    // maxHeight: "100vh",
    background: "linear-gradient(to right top, #2F464D, #6c8f99)",
  },
}));

function Layout(props) {
  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
}

export default Layout;
