import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";
import { makeStyles } from "@material-ui/core";

import Modal from "@material-ui/core/Modal";


const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10vh 10vw",
    height: "80vh",
    width: "80vw",
    borderRadius: "5vh",
  },
}));

function InfoChart(props) {
  const classes = useStyles();
  const { data } = props;
  const [chartData, setChartData] = useState("");

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      let dataForChart = [];
      const depts = data.map((d) => `${d.numDept} ${d.supervisor}`);
      const header = ["Depts.", ...depts];

      dataForChart.push(header);

      const nHours = data[0].schedule.length;
      for (let i = 0; i < nHours; i++) {
        let hour = [data[0].schedule[i].start];
        for (let d of data) {
          hour.push(d.schedule[i].efficiency);
        }

        dataForChart.push(hour);
      }


      setChartData(dataForChart);
    } else {
      setChartData([["Depts."]]);
    }
  }, [props.data]);

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <Modal
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
      className={classes.root}
    >
      <div>
        <Chart
          width={"100%"}
          height={"75vh"}
          chartType="AreaChart"
          loader={<div>Loading Chart</div>}
          data={chartData}
          options={{
            title: "Company Performance",
            hAxis: { title: "Year", titleTextStyle: { color: "#333" } },
            vAxis: { minValue: 0 },
            // For the legend to fit, we make the chart area smaller
            chartArea: { width: "50%", height: "70%" },
            // lineWidth: 25
          }}
        />
      </div>
    </Modal>
  );
}

export default InfoChart;
