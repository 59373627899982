import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import axios from "axios";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DescriptionIcon from "@material-ui/icons/Description";
import CircularProgress from "@material-ui/core/CircularProgress";


import Modal from "@material-ui/core/Modal";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10vh 10vw",
    height: "80vh",
    width: "80vw",
    borderRadius: "5vh",
  },
  content: {
    height: "80vh",
    width: "80vw",
    borderRadius: "5vh",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  input: {
    display: "none",
  },
  iconButton: {
    background: "#ccc",
    margin: "2rem",
  },
  fileName: {
    color: "#555",
    margin: "1rem",
  },
}));

function UploadFile(props) {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    props.handleClose();
  };

  const handleUploadFile = () => {
    var bodyFormData = new FormData();
    const clientFromStore = localStorage.getItem("client");
    if (clientFromStore && selectedFile) {
      bodyFormData.append("client", clientFromStore);
      bodyFormData.append("file", selectedFile);

      setIsLoading(true);

      axios
        // .post(
        //   "http://143.198.72.138:1500/api/tickets/createFromFile",
        //   bodyFormData
        // )
        .post(
          `${serverUrl}/api/tickets/createFromFile`,
          bodyFormData
        )
        
        .then((data) => {
          setIsLoading(false);
          props.handleOpenAlert({
            severity: "success",
            alertMessage: "File processed"
          });
          setSelectedFile(null);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err, err.response);
          props.handleOpenAlert({
            severity: "error",
            alertMessage: "File could not be processed: " + err.response?.data?.message? err.response.data.message : ""
          });
        });
    } else {
      props.handleOpenAlert({
        severity: "error",
        alertMessage: "Client or file not selected"
      });
    }
  };

  return (
    <Modal
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={props.isOpen}
      className={classes.root}
    >
      <div className={classes.content}>
        <div>
          <input
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            className={classes.input}
            id="icon-button-file"
            type="file"
            onChange={(ev) => {
              console.log(ev.target.files);
              if (ev.target.files.length > 0) {
                setSelectedFile(ev.target.files[0]);
                console.log(ev.target.files[0]);
              }
            }}
          />
          <label htmlFor="icon-button-file">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              className={classes.iconButton}
            >
              <DescriptionIcon fontSize="large" />
            </IconButton>
          </label>
        </div>
        {selectedFile ? (
          <div className={classes.fileName}>{selectedFile.name}</div>
        ) : (
          <></>
        )}
        {!isLoading ? (
          <Button
            onClick={handleUploadFile}
            color="primary"
            variant="contained"
          >
            Upload File
          </Button>
        ) : (
          <CircularProgress />
        )}
      </div>
    </Modal>
  );
}

export default UploadFile;
