import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  rowContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
  },
  gridTime: {
    border: "solid",
    height: "100%",
    width: "100%",
    borderWidth: "3px",
    borderColor: "rgba(150,150,150,1)",
    backgroundColor: " rgba(245,221,170,0.7)",
    fontWeight: 900,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.5rem",
    },
  },

  gridProjec: {
    height: "100%",
    width: "100%",
    border: "solid",
    borderWidth: "1px",
    borderColor: "rgba(150,150,150,1)",
    backgroundColor: " rgba(200,120,120,0.7)",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  gridActual: {
    height: "100%",
    width: "100%",
    border: "solid",
    borderWidth: "1px",
    borderColor: "rgba(150,150,150,1)",
    backgroundColor: " rgba(234,202,92,0.7)",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  gridEffi: {
    height: "100%",
    width: "100%",
    border: "solid",
    borderWidth: "1px",
    borderColor: "rgba(150,150,150,1)",
    backgroundColor: " rgba(96,177,158,0.7)",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  gridDamages: {
    height: "100%",
    width: "100%",
    border: "solid",
    borderWidth: "1px",
    borderColor: "rgba(150,150,150,1)",
    backgroundColor: " rgba(209,99,78,0.7)",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.3rem",
    },
  },
  damagesContainerCommon: {
    height: "100%",
  },
  damagesItemCommon: {
    height: "100%",
    padding: "0rem",
    background: "none",
    display: "none",
  },
  damagesItemDamaged: {
    height: "100%",
    padding: "0rem",
    background: "rgba(200,200,200,0.4)",
    borderWidth: "1px",
    borderColor: "rgba(150,150,150,1)",
    fontSize: "1.2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function DataRow(props) {
  const { row } = props;
  const classes = useStyles();

  const toFixedIfNecessary = (value, dp) => {
    return +parseFloat(value).toFixed(dp);
  };

  const hourToString = (hour) => {
    const h = Math.floor(hour);
    const min = Math.floor((hour - h) * 60, 0);

    const strHour = h < 10 ? "0" + h : h.toString();
    const strMin = min < 10 ? "0" + min : min.toString();

    return `${strHour}:${strMin}`;
  };

  const toShow = row.damage.filter((d) => d.quantity > 0);
  let rowSize = 4;
  if (toShow.length < 3) {
    rowSize = 12;
  } else if (toShow.length < 5) {
    rowSize = 6;
  }

  return (
    <div className={classes.rowContainer}>
      <div className={classes.gridTime}>
        {hourToString(row.start) + " - " + hourToString(row.end)}
      </div>
      <div className={classes.gridProjec}>
        {" "}
        {toFixedIfNecessary(row.project, 0)}{" "}
      </div>
      <div className={classes.gridActual}> {row.done} </div>
      <div className={classes.gridEffi}>
        {" "}
        {toFixedIfNecessary(row.efficiency, 2)}{" "}
      </div>
      <div className={classes.gridDamages}>
        <Grid container className={classes.damagesContainer}>
          {toShow.map((d) => (
            <Grid
              item
              xs={rowSize}
              key={d.id}
              className={
                d.quantity > 0
                  ? classes.damagesItemDamaged
                  : classes.damagesItemCommon
              }
            >
              {`${d.initials} ${d.quantity}`}
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default DataRow;
