import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Board from "./components/Board";
import {
  Grid,
  Fab,
  Modal,
  CircularProgress,
  Typography,
} from "@material-ui/core";

import SettingsIcon from "@material-ui/icons/Settings";
import { ArrowBackOutlined } from "@material-ui/icons";
import TimelineIcon from "@material-ui/icons/Timeline";
import axios from "axios";
import Configurations from "./components/Configurations";
import AddDamage from "./components/AddDamage";
import InfoChart from "./components/InfoChart";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import UploadFile from "./components/UploadFile";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import GenericModal from "./components/GenericModal";
import Layout from "./components/common/Layout";
import { getDateInFormat } from "./Utils";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const useStyles = makeStyles((theme) => ({
  loading: {
    position: "absolute",
    left: "25%",
    top: "47%",
    width: "50%",
    height: "5%",
    background:
      "linear-gradient(to left bottom, rgba(255,255,255,0.9), rgba(255,255,255,0.4))",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  progress: {
    fontSize: "2rem",
    fontWeight: 900,
    color: "rgb(200,120,120)",
  },
  mainContainer: {
    minHeight: "90vh",
    width: "90vw",
    borderRadius: "10px",
    background:
      "linear-gradient(to left bottom, rgba(255,255,255,0.2), rgba(255,255,255,0.1))",
  },
  settingsButton: {
    position: "absolute",
    top: "10px",
    right: "60px",
    width: "3em",
    height: "3em",
    background: "rgba(255,255,255,0.5)",
  },
  backButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    width: "3em",
    height: "3em",
    background: "rgba(255,255,255,0.5)",
  },
  chartButton: {
    position: "absolute",
    top: "10px",
    left: "10px",
    width: "3em",
    height: "3em",
    background: "rgba(255,255,255,0.5)",
  },
  modal: {
    margin: "10vh 10vw",
    height: "80vh",
    width: "80vw",
    borderRadius: "5px",
  },
}));

const fillTimeWithZeros = (num) => {
  return num < 10 ? "0" + num : num;
};

const getTimeInFormat = (date) => {
  var hour = fillTimeWithZeros(date.getHours());
  var minutes = fillTimeWithZeros(date.getMinutes());
  var seconds = fillTimeWithZeros(date.getSeconds());

  return `${hour}:${minutes}:${seconds}`;
};

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Home() {
  const classes = useStyles();
  const history = useHistory();

  const [scheduleData, setScheduleData] = useState([]);
  const [openConf, setOpenConf] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [openDamage, setOpenDamage] = useState(false);
  const [openChart, setOpenChart] = useState(false);
  const [openFileUpload, setOpenFileUpload] = useState(false);
  const [openGeneric, setOpenGeneric] = useState(false);

  const [selectedDept, setSelectedDept] = useState(null);

  const [damageTime, setDamageTime] = useState(getTimeInFormat(new Date()));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [openAlert, setOpenAlert] = useState(false);
  const [alerMessage, setAlertMessage] = useState("");
  const [severityMessage, setSeverityMessage] = useState("success");

  const [strDate, setStrDate] = useState("");

  useEffect(() => {
    getDataFromServer();

    const interval = setInterval(() => {
      getDataFromServer();
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setStrDate(getDateInFormat(new Date()));
    localStorage.setItem("date", getDateInFormat(new Date()));
  }, []);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const getDataFromServer = async () => {
    // setScheduleData([]);
    const dateSelected = localStorage.getItem("date");
    const clientFromStore = localStorage.getItem("client");
    const reportTypeFromStore = localStorage.getItem("reportType");

    let numdept1FromStore = localStorage.getItem("numdept1");
    let numdept2FromStore = localStorage.getItem("numdept2");
    const numdept3FromStore = localStorage.getItem("numdept3");
    const numdept4FromStore = localStorage.getItem("numdept4");

    if (
      (!numdept1FromStore || numdept1FromStore === "0") &&
      (!numdept2FromStore || numdept2FromStore === "0") &&
      (!numdept3FromStore || numdept3FromStore === "0") &&
      (!numdept4FromStore || numdept4FromStore === "0")
    ) {
      await axios
        .get(`${serverUrl}/api/tickets/departments/${clientFromStore}`)
        .then((deptData) => {
          numdept1FromStore = deptData?.data[0]?.numdept;
          numdept2FromStore = deptData?.data[1]?.numdept;
          localStorage.setItem("numdept1", deptData?.data[0]?.numdept);
          localStorage.setItem("numdept2", deptData?.data[1]?.numdept);
        })
        .catch((err) => {
          alert("Go back to config and select the client lines");
        });
    }

    let depts = [];

    if (numdept1FromStore && numdept1FromStore > 0) {
      depts.push(numdept1FromStore);
    }

    if (numdept2FromStore && numdept2FromStore > 0) {
      depts.push(numdept2FromStore);
    }

    if (numdept3FromStore && numdept3FromStore > 0) {
      depts.push(numdept3FromStore);
    }

    if (numdept4FromStore && numdept4FromStore > 0) {
      depts.push(numdept4FromStore);
    }

    setIsLoading(true);

    axios
      .post(`${serverUrl}/api/tickets/board`, {
        client: clientFromStore,
        date: dateSelected,
        lines: depts,
        reportType: reportTypeFromStore,
      })
      .then((data) => {
        setIsLoading(false);
        setScheduleData(data.data);
      })
      .catch((err) => {
        alert("Go back to home and select your correct client");
        setIsLoading(false);
      });
  };

  const numCols = scheduleData.length > 1 ? 2 : 1;

  const xsSize = 12 / numCols;

  //const mockupData = data.slice(0, 2);

  const closeConf = () => {
    setOpenConf(false);
    getDataFromServer();
  };

  const handleOpenDamage = (numDept) => {
    setSelectedDept(numDept);
    setDamageTime(getTimeInFormat(new Date()));
    setOpenDamage(true);
  };

  const handleCloseDamage = () => {
    setOpenDamage(false);
  };

  const handleCloseChart = () => {
    setOpenChart(false);
  };

  const handleCloseFileUpload = () => {
    setOpenFileUpload(false);
  };

  const handleCloseGeneric = () => {
    setOpenGeneric(false);
  };

  const openAlertFromComponent = (info) => {
    setAlertMessage(info.alertMessage);
    setSeverityMessage(info.severity);
    setOpenAlert(true);
  };

  const handleSaveDamage = (damageType, quantity) => {
    const srtDate = getDateInFormat(new Date());
    const clientFromStore = localStorage.getItem("client");

    setIsLoading(true);

    axios
      //.post("http://143.198.72.138:1500/api/tickets/damages", {
      .post(`${serverUrl}/api/tickets/damages`, {
        client: clientFromStore,
        date: srtDate,
        numdept: selectedDept,
        damagetype: damageType,
        time: `${srtDate} ${damageTime}`,
        quantity: quantity,
      })
      .then((data) => {
        setIsLoading(false);
        setOpenDamage(false);
        getDataFromServer();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Layout>
      <Typography align="center">{`Date: ${strDate}`}</Typography>
      <Grid container className={classes.mainContainer}>
        {scheduleData.map((d) => (
          <Grid item key={d.numDept} xs={xsSize}>
            <Board
              client={localStorage.getItem("client")}
              handleOpenDamage={handleOpenDamage}
              numCols={numCols}
              data={d}
            ></Board>
          </Grid>
        ))}
        {isLoading ? (
          <div className={classes.loading}>
            <CircularProgress
              className={classes.progress}
              disableShrink={true}
            />
            <Typography>Loading....</Typography>
          </div>
        ) : null}
      </Grid>
      <Fab
        className={classes.settingsButton}
        onClick={() => {
          setOpenConf(true);
        }}
      >
        <SettingsIcon />
      </Fab>
      <Fab
        className={classes.backButton}
        onClick={() => {
          history.push("/");
        }}
      >
        <ArrowBackOutlined />
      </Fab>

      <Fab className={classes.chartButton} onClick={handleOpenMenu}>
        <MenuBookIcon />
      </Fab>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <StyledMenuItem
          onClick={(ev) => {
            setOpenChart(true);
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <TimelineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Graphics" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={(ev) => {
            setOpenFileUpload(true);
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <CloudUploadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Upload Config File" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={(ev) => {
            setOpenGeneric(true);
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <InboxIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Generic Page that can be added" />
        </StyledMenuItem>
      </StyledMenu>
      <Modal open={openConf}>
        <div className={classes.modal}>
          <Configurations closeConf={closeConf} setStrDate={setStrDate} />
        </div>
      </Modal>

      <AddDamage
        time={damageTime}
        handleChangeTime={setDamageTime}
        handleClose={handleCloseDamage}
        handleSave={handleSaveDamage}
        isOpen={openDamage}
      />
      <InfoChart
        data={scheduleData}
        isOpen={openChart}
        handleClose={handleCloseChart}
      />
      <UploadFile
        isOpen={openFileUpload}
        handleOpenAlert={openAlertFromComponent}
        handleClose={handleCloseFileUpload}
      />
      <GenericModal isOpen={openGeneric} handleClose={handleCloseGeneric} />

      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={severityMessage}>
          {alerMessage}
        </Alert>
      </Snackbar>
    </Layout>
  );
}

export default Home;
