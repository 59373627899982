import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import TextField from "@material-ui/core/TextField";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Divider } from "@material-ui/core";

import axios from "axios";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    maxWidth: "550px",
    display: "flex",
    justifyContent: "space-between"
  },
  time: {
      width: "100px",
      margin: "0rem 2rem"
  }
}));

function AddDamage(props) {
  const classes = useStyles();

  const { isOpen } = props;
  const [damages, setDamages] = useState([]);
  const [selectedDamage, setSelectedDamage] = React.useState("");
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    axios
      // .get("http://143.198.72.138:1500/api/tickets/damages/usa")
      .get(`${serverUrl}/api/tickets/damages/usa`)
      
      .then((data) => {
        console.log(data.data);
        setDamages(data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleDamageChange = (event) => {
    console.log(event.target.value);
    setSelectedDamage(event.target.value.toString());
  };

  const handleChangeQuantity = (ev) => {
      const value = Math.max(0, ev.target.value);
      setQuantity(value);
  }

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = () => {
      if(selectedDamage && quantity > 0){
          props.handleSave(selectedDamage, quantity);
      }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Add damage"}</DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <TextField
          id="time"
          label="Insert Time"
          type="time"
          value={props.time}
          onChange={ev=>props.handleChangeTime(ev.target.value)}
          className={classes.time}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
        />
        <FormControl component="fieldset">
          <FormLabel component="legend">Damage Type</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="damage"
            value={selectedDamage}
            onChange={handleDamageChange}
          >
            {damages.map((d) => (
              <FormControlLabel
                key={d.id}
                value={d.id.toString()}
                control={<Radio />}
                label={`${d.type} (${d.initials})`}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <TextField
          id="quantity"
          label="Quantity"
          type="number"
          value={quantity}
          onChange={ev=>handleChangeQuantity(ev)}
          className={classes.time}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddDamage;
