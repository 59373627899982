import React from "react";
import { makeStyles } from "@material-ui/core";

import DataRow from "./DataRow";

const useStyles = makeStyles((theme) => ({
  board: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: " 1vh 1vw",
  },
  boardContainer: {
    height: "100%",
  },
  boardTitle: {
    //height: "5vh",
    backgroundColor: "rgba(56,55,90,0.5 )",
  },
  boardContent: {
    height: "90%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  rowContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
  },

  title: {
    color: "rgba(255,255,255,0.8)",
    fontWeight: 600,
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "0.4rem",
    },
    padding: "2% 0.5%",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    height: "100%",
  },
  grid: {
    height: "100%",
    width: "100%",
    border: "solid",
    borderWidth: "1px",
    borderColor: "rgb(100,100,100)",
    backgroundColor: "rgba(56,55,90,0.8 )",
    color: "rgba(255,255,255,0.8)",
    fontWeight: 600,
    padding: "2% 0.5%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "0.6rem",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "0.4rem",
    },
  },
}));

function Board(props) {
  const classes = useStyles();

  const handleOpenDamage = (numDept) => {
    props.handleOpenDamage(numDept);
  };

  const toFixedIfNecessary = (value, dp) => {
    return +parseFloat(value).toFixed(dp);
  };

  const { data } = props;

  return (
    <div className={classes.board}>
      <div className={classes.boardContainer}>
        <div className={classes.boardTitle}>
          <div className={classes.rowContainer}>
            <div className={classes.title}>
              {`CL : ${props.client ? props.client : ""}`}
            </div>
            <div className={classes.title}>{"SUP : " + data.supervisor}</div>
            <div className={classes.title}> {"MOD : " + data.numDept} </div>
            <div className={classes.title}>
              {" "}
              {"EFF : " +
                toFixedIfNecessary(data.efficiency.percent, 0) +
                "%"}{" "}
            </div>
            <div className={classes.title}>
              {" "}
              {"TO DO : " + data.totals.shouldBeDone}{" "}
            </div>
            <div className={classes.title}>
              {" "}
              {"DONE : " + data.totals.done}{" "}
            </div>
            <div className={classes.title}> {"RT : " + data.reportType} </div>
          </div>
        </div>
        <div className={classes.boardContent}>
          <div className={classes.rowContainer}>
            <div className={classes.grid}>HOURS</div>
            <div className={classes.grid}> PROJECT </div>
            <div className={classes.grid}> ACTUAL</div>
            <div className={classes.grid}> EFFICIENCY </div>
            <div
              className={classes.grid}
              onClick={() => handleOpenDamage(data.numDept)}
            >
              {" "}
              DAMAGE{" "}
            </div>
          </div>

          {data.schedule.map((r) => (
            <DataRow row={r} key={`${data.numDept}-${r.start}`} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Board;
